<template>
  <select-field
    class="w-full my-5"
    :icon="'money-bill-wave'"
    :label="'طريقة التحويل'"
    :placeholder="'إختيار طريقة تحويل'"
    :loading="loadingReceiveMethods"
    :options="receiveMethods"
    :value="selectedReceiveMethod"
    :allowSelectAll="true"
    @input="onChange"
  ></select-field>
</template>

<script lang="ts">
import { mapState } from "vuex";
import SelectField from "./SelectField.vue";

export default {
  components: { SelectField },
  computed: {
    ...mapState(["loadingReceiveMethods", "receiveMethods", "selectedReceiveMethod"]),
  },
  methods: {
    onChange(val) {
      this.$store.commit("setSelectReceiveMethod", val);
      this.$emit("input", val);
    },
  },
};
</script>
