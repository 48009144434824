import "./index.css";
import "vue-select/dist/vue-select.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGlobe,
  faAngleDown,
  faMoneyBillWave,
  faExchangeAlt,
  faSearch,
  faLayerGroup,
  faTimes,
  faClock,
  faHandHoldingUsd,
  faArrowLeft,
  faCheckDouble,
  faCircleNotch,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
import VueSelect from "vue-select";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

library.add(
  faGlobe,
  faAngleDown,
  faMoneyBillWave,
  faExchangeAlt,
  faSearch,
  faLayerGroup,
  faTimes,
  faClock,
  faMoneyBillWave,
  faHandHoldingUsd,
  faArrowLeft,
  faCheckDouble,
  faCircleNotch,
  faBars,
);

Vue.component("fa", FontAwesomeIcon);
Vue.component("v-select", VueSelect);

axios.defaults.baseURL = "https://hb.eyadroid.com/api/";

store.dispatch("getCountries");
store.dispatch("getReceiveMethods");

new Vue({
  router,
  store,
  el: "#app",
  render: (h) => h(App),
}).$mount("#app");
