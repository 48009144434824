<template>
  <div class="h-16 py-1 px-4 mt-2 border bg-white text-black rounded-sm flex flex-row">
    <div class="flex items-center justify-center text-base md:text-2xl">
      <fa :icon="['fas', icon]" />
    </div>
    <div class="flex flex-1 flex-col justify-center mx-4">
      <span class="text-sm" style="">{{ label }}</span>
      <v-select
        v-if="!loading"
        dir="rtl"
        :value="value"
        :options="selectOptions"
        :label="itemLabel"
        :placeholder="placeholder"
        :clearable="false"
        @input="setValue"
        class="w-full chooser"
      >
        <slot name="no-options">عذرًا ، لا توجد خيارات مطابقة.</slot>
      </v-select>
      <div v-else class="w-full text-base md:text-2xl px-2 text-left">
        <fa :icon="['fa', 'circle-notch']" class="animate-spin" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    console.log("options", this.options);
    console.log("value", this.value);
  },
  data() {
    return {
      selectIcon: {
        render: (createElement) => createElement("span", ""),
      },
      selected: null,
    };
  },
  props: {
    icon: String,
    value: Object,
    placeholder: String,
    label: String,
    allowSelectAll: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    itemLabel: {
      type: String,
      default: () => "name",
    },
  },
  computed: {
    selectOptions() {
      const data = [...this.options];
      if (this.allowSelectAll) {
        const selectAllOptions = {};
        selectAllOptions[this.itemLabel] = "الكل";
        data.unshift(selectAllOptions);
      }
      return data;
    },
  },
  methods: {
    setValue(val) {
      this.selected = val;
      this.$emit("input", val);
    },
  },
};
</script>

<style>
.chooser .vs__dropdown-toggle {
  border: none !important;
}
.chooser .vs__open-indicator {
  @apply fill-current text-2xl;
}
.chooser .vs__selected {
  @apply font-bold;
}
.chooser .vs__search {
  @apply placeholder-black;
}
.chooser .vs__search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  @apply font-bold;
}
.chooser .vs__search::-moz-placeholder {
  /* Firefox 19+ */
  @apply font-bold;
}
.chooser .vs__search:-ms-input-placeholder {
  /* IE 10+ */
  @apply font-bold;
}
.chooser .vs__search:-moz-placeholder {
  /* Firefox 18- */
  @apply font-bold;
}
</style>
