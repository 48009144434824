<template>
  <div
    class="
      w-full
      h-screen
      overflow-y-scroll
      md:overflow-hidden
      font-sans
      bg-white
      text-primary
      flex flex-col
    "
    style="background: #f2f6f9"
  >
    <router-view />
  </div>
</template>

<script lang="ts">
export default {};
</script>
