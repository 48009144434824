<template>
  <div class="shadow bg-white border-r-4 border-primary w-full h-auto px-5 py-5 my-2">
    <h1 class="text-base border-primary border-b-2 pb-5 mb-5">
      {{ transferMethod.title }}
    </h1>
    <div class="grid grid-cols-2 px-10 gap-y-5">
      <div class="flex flex-row items-start justify-start">
        <fa :icon="['fa', 'clock']" class="mx-5" />
        <p class="font-thin text-sm">{{ estimation }}</p>
      </div>
      <div class="flex flex-row items-start justify-start">
        <fa :icon="['fa', 'money-bill-wave']" class="mx-5" />
        <p class="font-thin text-sm">عمولة {{ commision }}</p>
      </div>
      <div class="flex flex-row items-start justify-start">
        <fa :icon="['fa', 'hand-holding-usd']" class="mx-5" />
        <p class="font-thin text-sm">إستلام {{ transferMethod.receive_method.name }}</p>
      </div>
      <div class="flex flex-row items-start justify-center">
        <a
          class="text-primary text-sm underline"
          :href="transferMethod.description"
          target="_blank"
        >
          التفاصيل
          <fa :icon="['fa', 'arrow-left']" class="mx-1" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import isoCountry from "iso-country-currency";

export default {
  setup() {},
  props: {
    transferMethod: {
      type: Object,
    },
  },
  computed: {
    commision() {
      if (this.transferMethod.no_commission) return "مجاناً";

      let value = "";
      let unit = "";

      if (!this.transferMethod.commission_value && this.transferMethod.commission_percent) {
        value = `${this.transferMethod.commission_percent}`;
        unit = "%";
      }
      if (this.transferMethod.commission_value && !this.transferMethod.commission_percent) {
        value = `${this.transferMethod.commission_value}`;
        unit = isoCountry.getAllInfoByISO(this.transferMethod.country.code).symbol;
      }
      return `${value} ${unit}`;
    },
    estimation() {
      if (this.transferMethod.immediate) return "فوري";
      return `${this.transferMethod.max_send_time_unit} - ${this.transferMethod.max_send_time_value} ${this.transferMethod.max_send_time_unit}`;
    },
  },
};
</script>
