<template>
  <div>
    <div class="fixed top-5 right-5">
      <fa
        :icon="['fas', 'times']"
        class="text-xl md:text-4xl text-primary cursor-pointer"
        @click="back"
      ></fa>
    </div>
    <div class="flex flex-row h-screen overflow-y-scroll pt-20 px-2 md:pr-5">
      <div class="w-full text-black flex flex-col">
        <div class="flex flex-col md:flex-row flex-1">
          <div class="flex-1">
            <div
              class="
                right-sidebar
                w-full
                md:fixed
                ml-5
                h-auto
                bg-white
                shadow
                flex flex-col
                justify-between
                text-lg
                px-5
                py-5
              "
            >
              <country-select />
              <receive-methods-select />
              <submit-button :loading="loadingTransferMethods" @click.native="search" />
            </div>
          </div>
          <div
            class="
              h-full
              flex flex-col
              items-center
              justify-start
              mt-2
              md:mt-0 md:mx-1 md:px-10
              flex-1
            "
          >
            <h1 class="font-bold mb-5 w-full text-right">نتائج البحث:</h1>
            <div
              class="
                h-full
                flex flex-col
                overflow-y-scroll
                scrollbar-thin
                scrollbar-thumb-primary
                scrollbar-thumb-rounded-full
                scrollbar-track-transparent
                md:px-5
                pb-5
              "
            >
              <item
                v-for="transferMethod in transferMethods"
                v-bind:key="transferMethod.id"
                :transferMethod="transferMethod"
              />
              <item
                v-for="transferMethod in transferMethods"
                v-bind:key="transferMethod.id"
                :transferMethod="transferMethod"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import CountrySelect from "../components/CountrySelect.vue";
import Item from "../components/Item.vue";
import ReceiveMethodsSelect from "../components/ReceiveMethodsSelect.vue";
import SubmitButton from "../components/SubmitButton.vue";

export default {
  components: {
    Item,
    CountrySelect,
    ReceiveMethodsSelect,
    SubmitButton,
  },
  methods: {
    back() {
      this.$router.back();
    },
    async search() {
      await this.$store.dispatch("getTransferMethods");
    },
  },
  computed: {
    ...mapState([
      "countries",
      "receiveMethods",
      "selectedCountry",
      "selectedReceiveMethod",
      "transferMethods",
      "loadingTransferMethods",
    ]),
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .right-sidebar {
    width: calc(50% - 2.5rem);
  }
}
</style>
