import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loadingCountries: false,
    loadingTransferMethods: false,
    loadingReceiveMethods: false,
    countries: [],
    transferMethods: [],
    receiveMethods: [],
    selectedCountry: null,
    selectedReceiveMethod: null,
  },
  getters: {
    loadingCountries(state) {
      return state.loadingCountries;
    },
    loadingTransferMethods(state) {
      return state.loadingTransferMethods;
    },
    countries(state) {
      return state.countries;
    },
    transferMethods(state) {
      return state.transferMethods;
    },
    selectedCountry(state) {
      return state.selectedCountry;
    },
    selectedReceiveMethod(state) {
      return state.selectedReceiveMethod;
    },
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setCountry(state, country) {
      state.selectedCountry = country;
    },
    setTransferMethods(state, methods) {
      state.transferMethods = methods;
    },
    setSelectReceiveMethod(state, method) {
      state.selectedReceiveMethod = method;
    },
    setReceiveMethods(state, methods) {
      state.receiveMethods = methods;
    },
  },
  actions: {
    async getCountries({ state, commit }) {
      state.loadingCountries = true;
      try {
        const { data } = await axios.get("countries");
        commit("setCountries", data.data.countries);
      } catch (e) {
        console.log(e);
      } finally {
        state.loadingCountries = false;
      }
    },
    async getReceiveMethods({ state, commit }) {
      state.loadingReceiveMethods = true;
      try {
        const { data } = await axios.get("receive_methods");
        commit("setReceiveMethods", data.data.receive_mthods);
      } catch (e) {
        console.log(e);
      } finally {
        state.loadingReceiveMethods = false;
      }
    },
    async getTransferMethods({ state, commit }) {
      state.loadingTransferMethods = true;
      try {
        const params = {};
        if (state.selectedCountry && state.selectedCountry.id) {
          params.country_id = state.selectedCountry.id;
        }
        if (state.selectedReceiveMethod && state.selectedReceiveMethod.id) {
          params.receive_method_id = state.selectedReceiveMethod.id;
        }
        const { data } = await axios.get("transfer_methods", {
          params,
        });
        commit("setTransferMethods", data.data.transfer_methods);
      } catch (e) {
        console.log(e);
      } finally {
        state.loadingTransferMethods = false;
      }
    },
  },
});
