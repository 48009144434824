<template>
  <div
    class="
      w-full
      h-16
      py-1
      px-4
      mt-2
      bg-primary
      text-white
      rounded-sm
      flex flex-row
      items-center
      justify-center
      text-base
      md:text-2xl
      font-bold
      cursor-pointer
      disabled:opacity-50
    "
    :disabled="loading"
    :class="{ 'opacity-70': loading }"
  >
    {{ loading ? "" : "إبحث" }}
    <fa :icon="['fa', 'circle-notch']" class="animate-spin" v-if="loading" />
    <!-- <fa :icon="['fas', 'search']" class="mx-2"></fa> -->
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
