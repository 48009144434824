<template>
  <select-field
    class="w-full my-5 mt-10"
    :icon="'globe'"
    :label="'الدولة'"
    :placeholder="'إختيار دولة'"
    :loading="loadingCountries"
    :options="countries"
    :value="selectedCountry"
    @input="onChange"
  ></select-field>
</template>
<script lang="ts">
import { mapState } from "vuex";
import SelectField from "./SelectField.vue";

export default {
  components: { SelectField },
  computed: {
    ...mapState(["loadingCountries", "countries", "selectedCountry"]),
  },
  methods: {
    onChange(val) {
      this.$store.commit("setCountry", val);
      this.$emit("input", val);
    },
  },
};
</script>
